import React, { memo } from "react";
import XMLViewer from "react-xml-viewer";

const FederationMetadata = () => {
  const url = window.location.hostname;

  const xmlContent = `<EntityDescriptor xmlns="urn:oasis:names:tc:SAML:2.0:metadata" ID="10CAFE87-EB56-4B2E-9F81-C1215F5025AF" entityID='https://${url}'>
<RoleDescriptor xmlns:fed="http://docs.oasis-open.org/wsfed/federation/200706" xmlns:xsi="http://www.w3.org/2001/XMLSchema-instance" xsi:type="fed:ApplicationServiceType" protocolSupportEnumeration="http://docs.oasis-open.org/wsfed/federation/200706">
<fed:ClaimTypesRequested>
<auth:ClaimType xmlns:auth="http://docs.oasis-open.org/wsfed/authorization/200706" Uri="http://schemas.xmlsoap.org/ws/2005/05/identity/claims/name" Optional="true"/>
<auth:ClaimType xmlns:auth="http://docs.oasis-open.org/wsfed/authorization/200706" Uri="http://schemas.microsoft.com/ws/2008/06/identity/claims/role" Optional="true"/>
</fed:ClaimTypesRequested>
<fed:TargetScopes>
<EndpointReference xmlns="http://www.w3.org/2005/08/addressing">
<Address>https://${url}</Address>
</EndpointReference>
</fed:TargetScopes>
<fed:PassiveRequestorEndpoint>
<EndpointReference xmlns="http://www.w3.org/2005/08/addressing">
<Address>https://${url}</Address>
</EndpointReference>
</fed:PassiveRequestorEndpoint>
</RoleDescriptor>
</EntityDescriptor>`;

  return (
    <>
      <XMLViewer xml={xmlContent} />
    </>
  );
};

export default memo(FederationMetadata);
