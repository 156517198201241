import { BaseMapper } from "./base.mapper";
import { AOExpense } from "infrastructure/interfaces";
import { get } from "lodash";

export class AOExpenseMapper extends BaseMapper<AOExpense, any> {
  responseToEntity(response: any): AOExpense {
    return {
      id: get(response, "id", 0),
      relations:
        response.relations.length > 0
          ? response.relations.map((relation: any) => ({
              relatedExpenseId: get(relation, "relatedExpenseId", 0),
              type: get(relation, "type", ""),
            }))
          : response.relations,
      expenseType: {
        id: get(response, "expenseType.id", -1),
        groupCode: get(response, "expenseType.groupCode", "Other"),
        code: get(response, "expenseType.code", "Other"),
        logicalName: get(response, "expenseType.logicalName", "Expense"),
        expenseGroup: {
          code: get(response, "expenseType.expenseGroup.code", "Other"),
        },
        name: {
          ru: get(response, "expenseType.name.ru", "Имя[STUB]"),
          en: get(response, "expenseType.name.en", "Name[STUB]"),
        },
        needIncludeToAdvanceReportAdditionalDocuments: get(
          response,
          "expenseType.needIncludeToAdvanceReportAdditionalDocuments",
          false,
        ),
        needIncludeToBusinessEventReportDocuments: get(
          response,
          "expenseType.needIncludeToBusinessEventReportDocuments",
          false,
        ),
      },
      description: get(response, "description", "описание[STUB]"),
      external: get(response, "external", false),
      isActive: get(response, "isActive", false),
      copiedFromExpenseApplicationId: get(
        response,
        "copiedFromExpenseApplicationId",
        0,
      ),
      advanceCashRequired: get(response, "advanceCashRequired", false),
      amount: get(response, "amount", 0),
      amountCurrency: {
        numCode: get(response, "amountCurrency.numCode", 0),
        name: get(response, "amountCurrency.name", "Name[STUB]"),
        id: get(response, "amountCurrency.id", 0),
        alfaCode: get(response, "amountCurrency.alphaCode", "rub"),
      },
      approvedAmount: get(response, "approvedAmount", 0) || 0,
      approvedAmountCurrency: {
        numCode: get(response, "approvedAmountCurrency.numCode", 0),
        name: {
          ru: get(response, "approvedAmountCurrency.name.ru", "Name[STUB]"),
          en: get(response, "approvedAmountCurrency.name.en", "Name[STUB]"),
        },
        id: get(response, "approvedAmountCurrency.id", 0),
        alfaCode: get(response, "approvedAmountCurrency.alphaCode", "rub"),
      },
      approvedAmountCurrencyRate:
        get(response, "approvedAmountCurrencyRate", 0) || 0,
      amountRub: get(response, "amountRub", 0),
      paymentFormId: {
        id: get(response, "paymentForm.id", 0),
        name: {
          ru: get(response, "paymentForm.name.ru", "Имя[STUB]"),
          en: get(response, "paymentForm.name.en", "Name[STUB]"),
        },
      },
      status: get(response, "status", false),
      approvedAmountRub: get(response, "approvedAmountRub", 0),
      billNumber: get(response, "billNumber", ""),
      approvedAmountComment: get(response, "approvedAmountComment", ""),
      billDate: get(response, "billDate", null),
      amountCurrencyRate: get(response, "amountCurrencyRate", 0),
      notUsed: get(response, "notUsed", false),
      showNotAttachmentAlert: get(response, "showNotAttachmentAlert", false),
      accountableFormNumber: get(response, "accountableFormNumber", ""),
      properties: get(response, "properties", []),
    };
  }

  responsesToEntitys(responses: any[]): AOExpense[] {
    return [...responses.map((item) => this.responseToEntity(item))];
  }
}
