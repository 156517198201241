import React, { useState, useEffect, Fragment } from "react";
import { useTranslation } from "react-i18next";
import ModalContainer from "app/component/modal/ModalContainer";
import { useSelector, useDispatch } from "react-redux";
import { showErrors } from "store/exception/actions";
import { RadioButton } from "ui/RadioButton";
import { Checkbox } from "ui/Checkbox";
import { getNextKey } from "app/utils";
import OccupationAutocomplete from "../autocomplete/OccupationAutocomplete";
import { getCommonExpenseTypes } from "../../../store/selectors";

interface AddMeetingParticipantsModalProps {
  isOpen: string;
  onClose: () => void;
  onComplete: (participant: any) => void;
  includesParticipants: any;
  showOtherParticipants: boolean;
}

const AddMeetingParticipantsModal: React.FC<
  AddMeetingParticipantsModalProps
> = (props) => {
  let { t, i18n } = useTranslation();

  const expenseTypes = useSelector(getCommonExpenseTypes);

  const dispatch = useDispatch();

  let [participant, setParticipant] = useState({
    key: 1,
    id: 0,
    isEmployee: false,
    isResponsible: false,
    occupationId: 0,
    name: "",
    role: "",
    companyId: 5,
    companyName: "",
  });
  let [fieldsWithError, setFieldsWithError] = useState([] as string[]);
  let [isTabIndexDisabled, setTabIndexDisabled] = useState(false);

  useEffect(() => {
    if (props.isOpen == "employee" || props.isOpen == "other") {
      setParticipant({
        key: getNextKey("actParticipant"),
        id: 0,
        isEmployee: props.isOpen == "employee",
        isResponsible: false,
        occupationId: 0,
        name: "",
        role: "",
        companyId: 5,
        companyName: "",
      });
    }
  }, [props.isOpen]);

  const changeValue = (type: string, value: any) => {
    let newFieldsWithError = [...fieldsWithError];
    let newParticipant = { ...participant };

    switch (type) {
      case "name":
        newParticipant.name = value.target.value;
        newFieldsWithError = newFieldsWithError.filter(
          (item) => item != "name"
        );
        break;
      case "role":
        newParticipant.role = value.target.value;
        newFieldsWithError = newFieldsWithError.filter(
          (item) => item != "role"
        );
        break;
      case "companyName":
        newParticipant.companyName = value.target.value;
        newFieldsWithError = newFieldsWithError.filter(
          (item) => item != "company"
        );
        break;
      case "responsible":
        newParticipant.isResponsible = !participant.isResponsible;
        break;
      case "employee":
        newParticipant.isEmployee = value;
        newParticipant.isResponsible = false;
        newFieldsWithError = [];
        break;
      case "occupationAutocomplete":
        if (value.value) {
          newParticipant.occupationId = value.occupation.id;
          newParticipant.name = value.employee.name[i18n.language as "ru"];
          newParticipant.role = value.occupation.position
            ? value.occupation.position[i18n.language as "ru"]
            : "";
          newParticipant.companyName =
            value.occupation.company.name[i18n.language as "ru"];
          newFieldsWithError = newFieldsWithError.filter(
            (item) => item != "occupation"
          );
        } else {
          newParticipant.occupationId = 0;
          newParticipant.name = "";
          newParticipant.role = "";
          newParticipant.companyName = "";
          newFieldsWithError = newFieldsWithError.filter(
            (item) => item != "occupation"
          );
        }
        break;
    }

    setFieldsWithError(newFieldsWithError);
    setParticipant(newParticipant);
  };

  const validate = () => {
    let newFieldsWithError = [];

    let validOccupation = true;

    let validName = true;
    let validRole = true;
    let validCompany = true;

    if (participant.isEmployee) {
      let hasOcupation = props.includesParticipants.map(
        (item: any) => item.occupationId
      );
      validOccupation = !hasOcupation.includes(participant.occupationId);
      if (!validOccupation) {
        dispatch(
          showErrors({
            code: "del_substituation",
            message: "РЎРѕС‚СЂСѓРґРЅРёРє СѓР¶Рµ РїСЂРёРІСЏР·Р°РЅ",
          })
        );
      }

      if (!participant.occupationId) {
        validOccupation = false;
        newFieldsWithError.push("occupation");
      }
    } else {
      if (!participant.name.trim().length) {
        validName = false;
        newFieldsWithError.push("name");
      }

      if (!participant.role.trim().length) {
        validRole = false;
        newFieldsWithError.push("role");
      }

      if (!participant.companyName.trim().length) {
        validCompany = false;
        newFieldsWithError.push("company");
      }
    }

    setFieldsWithError(newFieldsWithError);
    return validOccupation && validName && validRole && validCompany;
  };

  const validStateField = (fieldName: string) => {
    return fieldsWithError.includes(fieldName) ? "error" : "";
  };

  const closeHandler = () => {
    props.onClose();
  };

  const saveHandler = () => {
    if (!validate()) {
      return;
    }
    let resultParticipant = {
      ...participant,
      occupationId: participant.isEmployee ? participant.occupationId : 0,
    };
    props.onComplete(resultParticipant);
  };

  const isHighestModal = (isHighest: boolean) => {
    setTabIndexDisabled(!isHighest);
  };

  return (
    <Fragment>
      <ModalContainer
        isOpen={props.isOpen == "employee" || props.isOpen == "other"}
        destroy={true}
        highestModal={isHighestModal}
        overlayClick={closeHandler}
      >
        <div className="box-modal" id="add-expenses1">
          <div className="box-modal_close" onClick={closeHandler}></div>
          <div className="box-modal-title">
            {t("modals.ad_act.participants.title")}
          </div>
          <div className="box-modal-content">
            <div className="box-modal-form">
              <div className="box-modal-form-block">
                <div className="input-item-row">
                  <div
                    className={`input-item ${validStateField("occupation")}`}
                    style={{
                      display: participant.isEmployee ? undefined : "none",
                    }}
                  >
                    <label className="input-label">
                      {t("modals.ad_act.participants.fio_role")}
                    </label>
                    <div className="input-search">
                      <OccupationAutocomplete
                        onChoose={(option) => {
                          changeValue("occupationAutocomplete", option);
                        }}
                        placeholder={t("journal.filters.enter_name")}
                        withPosition={true}
                        tabIndex={isTabIndexDisabled ? -1 : 0}
                      />
                    </div>
                  </div>
                  <div
                    className={`input-item ${validStateField("name")}`}
                    style={{
                      display: participant.isEmployee ? "none" : undefined,
                    }}
                  >
                    <label className="input-label">
                      {t("modals.ad_act.participants.fio")}
                    </label>
                    <input
                      className="input"
                      type="text"
                      onChange={(event) => changeValue("name", event)}
                      value={participant.name}
                      placeholder={t(
                        "modals.ad_act.participants.fio_placeholder"
                      )}
                      tabIndex={isTabIndexDisabled ? -1 : 0}
                    />
                  </div>
                  <div
                    className={`input-item ${validStateField("role")}`}
                    style={{
                      display: participant.isEmployee ? "none" : undefined,
                    }}
                  >
                    <label className="input-label">
                      {t("modals.ad_act.participants.role")}
                    </label>
                    <input
                      className="input"
                      type="text"
                      onChange={(event) => changeValue("role", event)}
                      value={participant.role}
                      placeholder={t(
                        "modals.ad_act.participants.role_placeholder"
                      )}
                      tabIndex={isTabIndexDisabled ? -1 : 0}
                    />
                  </div>
                  <div
                    className="input-item"
                    style={{
                      width: "171px",
                      marginRight: "0px",
                      display: participant.isEmployee ? undefined : "none",
                    }}
                  >
                    <Checkbox
                      name={"responsible"}
                      tabIndex={isTabIndexDisabled ? -1 : 0}
                      id={1}
                      label={t("modals.ad_act.participants.responsible")}
                      checked={participant.isResponsible}
                      onClick={() => changeValue("responsible", {})}
                    />
                  </div>
                  <div
                    className={`input-item ${validStateField("company")}`}
                    style={{
                      display: participant.isEmployee ? "none" : undefined,
                    }}
                  >
                    <label className="input-label">
                      {t("modals.ad_act.participants.company")}
                    </label>
                    <input
                      className="input"
                      type="text"
                      onChange={(event) => changeValue("companyName", event)}
                      value={participant.companyName}
                      placeholder={t(
                        "modals.ad_act.participants.company_placeholder"
                      )}
                      tabIndex={isTabIndexDisabled ? -1 : 0}
                    />
                  </div>
                </div>
                <div className="box-modal-checkbox-wrap m-b-15">
                  <RadioButton
                    checked={participant.isEmployee}
                    label={t("modals.ad_act.participants.employee")}
                    onClick={() => changeValue("employee", true)}
                    id={1}
                    name={"radoii"}
                    key={1}
                  />
                  {props.showOtherParticipants && (
                    <RadioButton
                      checked={!participant.isEmployee}
                      label={t("modals.ad_act.participants.other")}
                      onClick={() => changeValue("employee", false)}
                      id={2}
                      name={"radoii"}
                      key={2}
                    />
                  )}
                </div>
              </div>
            </div>
          </div>
          <div className="box-modal-footer">
            <a
              className="btn btn_black pointer"
              onClick={closeHandler}
              tabIndex={isTabIndexDisabled ? -1 : 0}
            >
              {t("modals.create_advance_report_modal.cancel")}
            </a>
            <a
              className="btn-expense btn_green pointer"
              onClick={saveHandler}
              tabIndex={isTabIndexDisabled ? -1 : 0}
            >
              {t("modals.create_advance_report_modal.complete")}
            </a>
          </div>
        </div>
      </ModalContainer>
    </Fragment>
  );
};

export default AddMeetingParticipantsModal;
