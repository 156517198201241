import { BaseMapper } from "./base.mapper";
import { ConsumptionType } from "infrastructure/interfaces";
import { get } from "lodash";

export class ConsumptionTypeMapper extends BaseMapper<ConsumptionType, any> {
  responseToEntity(response: any): ConsumptionType {
    return {
      id: get(response, "id", 0),
      name: {
        ru: get(response, "name.ru", ""),
        en: get(response, "name.en", ""),
      },
      code: get(response, "code", ""),
      groupCode: get(response, "groupCode", ""),
      expenseGroup: {
        code: get(response, "expenseGroup.code", ""),
      },
      alternateKey: get(response, "alternateKey", ""),
      needIncludeToAdvanceReportAdditionalDocuments: get(
        response,
        "needIncludeToAdvanceReportAdditionalDocuments",
        false,
      ),
      needIncludeToBusinessEventReportDocuments: get(
        response,
        "needIncludeToBusinessEventReportDocuments",
        false,
      ),
    };
  }
}
