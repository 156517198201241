import React, { useState } from "react";
import "./AOExpenses.css";
import { useTranslation } from "react-i18next";
import DeleteExpenseModal from "../../component/modal/DeleteExpenseModal";
import { IAO } from "../../../infrastructure/interfaces";
import { useDispatch, useSelector } from "react-redux";
import { loaderLock, loaderUnlock } from "../../../store/common/actions";
import Svgicon from "ui/SvgIcon/SvgIcon";
import ADActModal from "app/component/modal/ADActModal";
import { ADVANCE_APPLICATION_PROPETIES } from "../../../infrastructure/enum/object-properties.enum";
import { getAdvanceReportApplication } from "../../../store/selectors";
import { updateDetailedAO } from "../../../store/report/actions";
import MemoModal from "app/component/modal/MemoModal/MemoModal";
import {
  DropdownButtonContainer,
  DropdownButtonItem,
} from "app/component/modal/MemoModal/MemoModalStyled";
import AODocument from "./AODocument";

interface AOListDocumentsProps {
  documentArr: any[];
}

const AODocuments: React.FC<AOListDocumentsProps> = (props) => {
  const { t } = useTranslation();

  const dispatch = useDispatch();

  const advanceReportApplication: IAO = useSelector(
    getAdvanceReportApplication
  );

  const [actType, setActType] = useState("");

  const [actId, setActId] = useState(0);
  const [memoId, setMemoId] = useState(0);

  const [isOpenActModal, setOpenActModal] = useState(false);
  const [isOpenMemoModal, setOpenMemoModal] = useState(false);
  const [isOpenDeleteExpenseModal, setOpenDeleteExpenseModal] = useState(false);

  const isDocumentPresent =
    advanceReportApplication.properties.includes(
      ADVANCE_APPLICATION_PROPETIES.ACT_OF_ENTERTAINMENT_EXPENSES
    ) ||
    advanceReportApplication.properties.includes(
      ADVANCE_APPLICATION_PROPETIES.SERVICE_NOTE
    ) ||
    advanceReportApplication.properties.includes(
      ADVANCE_APPLICATION_PROPETIES.BUSINESS_EVENT_REPORT
    );

  const [isOpenDocumentDropDownButton, setIsOpenDocumentDropDownButton] =
    useState(false);

  const handleActAdd = (type: string) => {
    setActId(0);
    setActType(type);
    setOpenActModal(true);
    setIsOpenDocumentDropDownButton(false);
  };

  const handleMemoAdd = () => {
    setMemoId(0);
    setOpenMemoModal(true);
    setIsOpenDocumentDropDownButton(false);
  };

  const handleActClose = () => {
    setOpenActModal(false);
  };
  const handleMemoClose = () => {
    setOpenMemoModal(false);
  };

  const handleComplete = () => {
    setOpenActModal(false);
    setOpenMemoModal(false);
    dispatch(updateDetailedAO(advanceReportApplication.id));
  };

  const toggleDeleteExpenseModal = () => {
    setOpenDeleteExpenseModal(!isOpenDeleteExpenseModal);
  };

  const handleDocumentDropDown = () => {
    setIsOpenDocumentDropDownButton(!isOpenDocumentDropDownButton);
  };

  const handleDelete = async () => {
    dispatch(loaderLock());
    dispatch(loaderUnlock());
  };

  return (
    <>
      <div
        className="request-expenses"
        style={{
          display: isDocumentPresent ? undefined : "none",
        }}
      >
        <div className="request-expenses-title" style={{ height: "40px" }}>
          <span>
            {t("request_detail.application_expenses.representationExpenses")}
          </span>
          {isDocumentPresent ? (
            <div
              style={{
                display: "flex",
                alignItems: "center",
                position: "relative",
              }}
            >
              <a
                className="btn btn_gray pointer"
                onClick={handleDocumentDropDown}
                style={{
                  width: "100px",
                  height: "40px",
                  textTransform: "none",
                  fontSize: "16px",
                }}
              >
                {t("request_detail.application_expenses.addDocument")}
              </a>
              {isOpenDocumentDropDownButton && (
                <DropdownButtonContainer>
                  {advanceReportApplication.properties.includes(
                    ADVANCE_APPLICATION_PROPETIES.ACT_OF_ENTERTAINMENT_EXPENSES
                  ) && (
                    <DropdownButtonItem
                      onClick={() =>
                        handleActAdd(
                          ADVANCE_APPLICATION_PROPETIES.ACT_OF_ENTERTAINMENT_EXPENSES
                        )
                      }
                    >
                      <Svgicon id={"docBlank"} />
                      {t("request_detail.application_expenses.addAct")}
                    </DropdownButtonItem>
                  )}

                  {advanceReportApplication.properties.includes(
                    ADVANCE_APPLICATION_PROPETIES.SERVICE_NOTE
                  ) && (
                    <DropdownButtonItem onClick={handleMemoAdd}>
                      <Svgicon id={"docFilled"} />
                      {t("request_detail.application_expenses.addMemo")}
                    </DropdownButtonItem>
                  )}
                  {advanceReportApplication.properties.includes(
                    ADVANCE_APPLICATION_PROPETIES.BUSINESS_EVENT_REPORT
                  ) && (
                    <DropdownButtonItem
                      onClick={() =>
                        handleActAdd(
                          ADVANCE_APPLICATION_PROPETIES.BUSINESS_EVENT_REPORT
                        )
                      }
                    >
                      <Svgicon id={"fileDownload"} color="#545B5E" />
                      {t("request_detail.application_expenses.addReport")}
                    </DropdownButtonItem>
                  )}
                </DropdownButtonContainer>
              )}
            </div>
          ) : null}
        </div>
        <div className="request-expenses-wrap">
          {props.documentArr.length
            ? props.documentArr.map((item) => {
                return (
                  <AODocument
                    item={item}
                    setActId={setActId}
                    setOpenActModal={setOpenActModal}
                    setMemoId={setMemoId}
                    setOpenMemoModal={setOpenMemoModal}
                  ></AODocument>
                );
              })
            : null}
        </div>
        <ADActModal
          documentType={actType}
          isOpen={isOpenActModal}
          editId={actId}
          documentId={advanceReportApplication.id}
          onClose={handleActClose}
          onComplete={handleComplete}
        />
        <MemoModal
          isOpen={isOpenMemoModal}
          editId={memoId}
          documentId={advanceReportApplication.id}
          onClose={handleMemoClose}
          onComplete={handleComplete}
        />
        <DeleteExpenseModal
          id={0}
          isOpen={isOpenDeleteExpenseModal}
          onSubmit={handleDelete}
          onClose={toggleDeleteExpenseModal}
        />
      </div>
    </>
  );
};

export default AODocuments;
