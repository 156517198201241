import React, { useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import { Input, UpperLabel } from "../PowerOfAttorneyCommonStyled";
import CompanyAutocomplete, {
  CompanyAutocompleteOptionType,
} from "app/component/autocomplete/CompanyAutocomplete";
import Validator from "app/component/validator/Validator";
import { useSelector } from "react-redux";
import { getPowerOfAttorneyDetail } from "store/selectors";

type Props = {
  companyId: number;
  setCompany: React.Dispatch<
    React.SetStateAction<CompanyAutocompleteOptionType>
  >;
  company: CompanyAutocompleteOptionType;
  ref: any;
  clearFields: () => void;
};

const HoldingCompanies: React.FC<Props> = ({
  companyId,
  setCompany,
  company,
  ref,
  clearFields,
}) => {
  const { t } = useTranslation();
  const POW = useSelector(getPowerOfAttorneyDetail);

  const companyChooseHandler = (option: CompanyAutocompleteOptionType) => {
    if (option && company && option.value !== company.value) {
      setCompany(option);
      clearFields();
    }
  };

  return (
    <div>
      <div className="input-item-row" style={{ marginBottom: "5px" }}>
        <Validator
          type={"text"}
          className={"fl-grow"}
          style={{ marginBottom: "1px" }}
        >
          <>
            <UpperLabel>
              {t("power.name_of_the_organization")}
              <i className="input-required"></i>
            </UpperLabel>
            <div className="input-search">
              <CompanyAutocomplete
                ref={ref}
                onChoose={companyChooseHandler}
                miniVector="vectorDown"
                comboBox={true}
                defaultText={(POW && POW.representative) ? POW.representative.company.name.ru : ""}
                powerOfAttorney={true}
                companyId={companyId}
              />
            </div>
          </>
        </Validator>
      </div>

      <div className="d-flex w-100 flex-column">
        <UpperLabel>{t("power.registered_address")}</UpperLabel>
        <Input
          value={company.address}
          disabled={true}
          defaultValue={
            POW.representative ? POW.representative.company.legal.address : ""
          }
        />
      </div>

      <div className="row">
        <div className="d-flex flex-column col-4">
          <UpperLabel>{t("power.INN")}</UpperLabel>
          <Input
            disabled={true}
            value={company.inn}
            defaultValue={
              POW.representative ? POW.representative.company.legal.inn : ""
            }
          />
        </div>
        <div className="d-flex w-100 flex-column col-4">
          <UpperLabel>{t("power.OGRN")}</UpperLabel>
          <Input
            disabled={true}
            value={company.ogrn}
            defaultValue={
              POW.representative ? POW.representative.company.legal.ogrn : ""
            }
          />
        </div>
        <div className="d-flex w-100 flex-column col-4">
          <UpperLabel>{t("power.KPP")}</UpperLabel>
          <Input
            disabled={true}
            value={company.kpp}
            defaultValue={
              POW.representative ? POW.representative.company.legal.kpp : ""
            }
          />
        </div>
      </div>
    </div>
  );
};

export default HoldingCompanies;
