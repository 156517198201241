import React, { useState, useEffect } from "react";
import SvgIcon from "app/component/svg-icon/SvgIcon";
import { useTranslation } from "react-i18next";
import { ApprovalEmployee } from "infrastructure/interfaces";
import If from "../util/If";
import Else from "app/component/util/Else";
import AgreementItemRow from "./AgreementItemRow";
import { AGREEMENT_RESOLUTION_TYPE } from "infrastructure/enum/agreement-resolution-type.enum";
import { ApprovalItem } from "infrastructure/interfaces/ApprovalItem.interface";
import { ApprovalTierDocuments } from "infrastructure/interfaces/ApprovalTierDocuments.interface";
import { getAdvanceReportApplication } from "store/selectors";
import { useSelector } from "react-redux";
import Config, { ConfigTypes } from "services/ConfigService";
import { ADVANCE_APPLICATION_PROPETIES } from "infrastructure/enum/object-properties.enum";

interface AgreementItemProps {
  id: number;
  title: string;
  users: ApprovalEmployee[];
  offset: number;
  toggleFullList: boolean;
  isAdvance?: boolean;
  approvalItems?: ApprovalItem[];
  approvalTierDocuments?: ApprovalTierDocuments[];
}

const AgreementItem: React.FC<AgreementItemProps> = (props) => {
  const { t, i18n } = useTranslation();
  let [statusSpan, setStatusSpan] = useState(<span></span>);
  let [isOpenFullList, setOpenFullList] = useState(false);
  let [approvedUser, setApprovedUser] = useState(
    undefined as ApprovalEmployee | undefined
  );
  let [additionalUsers, setAdditionalUsers] = useState(
    [] as ApprovalEmployee[]
  );
  let [additionalStatus, setAdditionalStatus] = useState("");
  let [isApproved, setIsApproved] = useState(false);

  const report = useSelector(getAdvanceReportApplication);
  const companyId = report && report.company ? report.company.id : 0;
  const isSigningEnabled = Config.getConfigToCompany(
    ConfigTypes.ADVANCE_REPORT_APPLICATION_SIGNING_ENABLED,
    companyId
  );

  const getSignatureType = (signature: undefined | String[]) => {
    if (signature && signature.length === 1) {
      if (signature.includes("SES")) {
        return "ПЭП";
      }
      if (signature.includes("AQES")) {
        return "УКЭП";
      }
      if (signature.includes("AES")) {
        return "УНЭП";
      }
      return "";
    }
    if (
      signature &&
      signature.length > 1 &&
      signature.includes("AQES") &&
      signature.includes("AES")
    ) {
      return "УКЭП, УНЭП";
    }
    return "";
  };

  const getDocumentsType = (
    documents: ApprovalTierDocuments[] | undefined
  ): string => {
    if (documents) {
      const documentsFilter = documents
        .filter((document) => document.isSignatureRequired === true)
        .map((document) => {
          if (document.objectLogicalName === "AdvanceReportApplication") {
            return "АО";
          }
          if (document.advanceReportAdditionalDocumentType === "ServiceNote") {
            return "СЗ";
          }
          if (
            document.advanceReportAdditionalDocumentType ===
            ADVANCE_APPLICATION_PROPETIES.BUSINESS_EVENT_REPORT
          ) {
            return "ОВМ";
          }
          if (
            document.objectLogicalName === "AdvanceReportAdditionalDocument" &&
            document.advanceReportAdditionalDocumentType !== "ServiceNote"
          ) {
            return "АКТ";
          }
          return "";
        });
      return [...Array.from(new Set(documentsFilter))].join(", ");
    }
    return "";
  };

  const isSignature =
    props.approvalTierDocuments && props.approvalTierDocuments.length > 0;

  const toggleOpenFullList = () => {
    setOpenFullList(!isOpenFullList);
  };

  useEffect(() => {
    setOpenFullList(props.toggleFullList);
  }, [props.toggleFullList]);

  useEffect(() => {
    if (props.users.length > 0) {
      let statuses = props.users.map((item) => item.resolution);

      if (statuses.includes(AGREEMENT_RESOLUTION_TYPE.REVOKED)) {
        setStatusSpan(
          <span className="red">
            {t("agreement.list.title_status.revoked")}
          </span>
        );
      } else if (
        statuses.includes(AGREEMENT_RESOLUTION_TYPE.APPROVED) &&
        !statuses.includes(AGREEMENT_RESOLUTION_TYPE.ON_APPROVAL)
      ) {
        setStatusSpan(
          <span className="green">
            {t("agreement.list.title_status.approved")}
          </span>
        );
        let approvedUser = props.users.filter(
          (user) => user.resolution == AGREEMENT_RESOLUTION_TYPE.APPROVED
        );
        approvedUser.length > 0 && setApprovedUser(approvedUser[0]);
        setIsApproved(true);
      } else if (statuses.includes(AGREEMENT_RESOLUTION_TYPE.NOT_APPROVED)) {
        setStatusSpan(
          <span className="red">
            {t("agreement.list.title_status.revoked")}
          </span>
        );
      } else if (statuses.includes(AGREEMENT_RESOLUTION_TYPE.REVISION)) {
        setStatusSpan(
          <span className="gray">
            {t("agreement.list.title_status.revision")}
          </span>
        );
      } else {
        setStatusSpan(
          <span className="gray">{t("agreement.list.title_status.wait")}</span>
        );
      }
    }

    if (props.users.length > 1) {
      setAdditionalUsers(props.users);

      let statuses = props.users.slice(1).map((item) => item.resolution);

      if (statuses.includes(AGREEMENT_RESOLUTION_TYPE.REVOKED)) {
        setAdditionalStatus(t("agreement.list.user_status.revoked"));
      } else if (
        statuses.includes(AGREEMENT_RESOLUTION_TYPE.APPROVED) &&
        !statuses.includes(AGREEMENT_RESOLUTION_TYPE.ON_APPROVAL)
      ) {
        setAdditionalStatus(t("agreement.list.user_status.approved"));
      } else {
        setAdditionalStatus(t("agreement.list.user_status.wait"));
      }
    }
  }, [props.users, i18n.language]);

  return (
    <If condition={props.users.length > 0}>
      <div className="list-agreement-item">
        <div className="list-agreement-title">
          <div className="num">
            <span>{props.id - props.offset}</span>
          </div>
          <div>
            {props.title} — {statusSpan}
          </div>
        </div>
        <div className="list-agreement-table-wrap">
          <div className="list-agreement-table">
            <div className="list-agreement-table-row">
              <div className="th">{t("agreement.list.table.matching")}</div>
              <div className="th">{t("agreement.list.table.occupation")}</div>
              <div className="th">{t("agreement.list.table.group")}</div>
              <div className="th">{t("agreement.list.table.date")}</div>
              {isSignature && isSigningEnabled && (
                <>
                  <div className="th">{t("certificate.list_table_sign")}</div>
                  <div className="th">{t("certificate.list_table_type")}</div>
                </>
              )}
              <div className="th"> </div>
            </div>
            <If condition={isApproved}>
              <AgreementItemRow
                user={approvedUser}
                id={props.id}
                isAdvance={props.isAdvance}
                signatureType={
                  isSignature &&
                  getSignatureType(
                    props.approvalTierDocuments &&
                      props.approvalTierDocuments[0].signatureTypes
                  )
                }
                documentType={getDocumentsType(props.approvalTierDocuments)}
                isSignature={isSignature}
              />
            </If>
            <Else condition={isApproved}>
              <If condition={props.users.length == 1}>
                <AgreementItemRow
                  user={props.users[0]}
                  id={props.id}
                  isAdvance={props.isAdvance}
                  signatureType={
                    isSignature &&
                    getSignatureType(
                      props.approvalTierDocuments &&
                        props.approvalTierDocuments[0].signatureTypes
                    )
                  }
                  documentType={getDocumentsType(props.approvalTierDocuments)}
                  isSignature={isSignature}
                />
              </If>
              <If condition={props.users.length > 1}>
                <div className="list-agreement-table-row parent">
                  <div
                    className={"td " + (isOpenFullList ? "active" : "")}
                    onClick={toggleOpenFullList}
                  >
                    <SvgIcon
                      className="icon icon-table-arrow"
                      href="#svg_icon_table-arrow"
                    />
                    {t("agreement.list.table.next")}
                  </div>
                  <div className="td">{""}</div>
                  <div className="td">{""}</div>
                  <div className="td">{""}</div>
                  <div className="td">{""}</div>
                  <div className="td">{""}</div>
                  <div className="td">{/* TODO agregate cost centr */}</div>
                  <div
                    className="list-agreement-table child"
                    style={{ display: isOpenFullList ? "block" : "none" }}
                  >
                    {additionalUsers.map((user, i) => {
                      return (
                        <AgreementItemRow
                          user={user}
                          id={props.id}
                          isAdvance={props.isAdvance}
                          signatureType={
                            isSignature &&
                            getSignatureType(
                              props.approvalTierDocuments &&
                                props.approvalTierDocuments[0].signatureTypes
                            )
                          }
                          documentType={getDocumentsType(
                            props.approvalTierDocuments
                          )}
                          isSignature={isSignature}
                        />
                      );
                    })}
                  </div>
                </div>
              </If>
            </Else>
          </div>
        </div>
      </div>
    </If>
  );
};

export default AgreementItem;
