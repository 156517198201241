import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import CreateOrderModal from "../modal/CreateOrderModal";
import SubmitCustomModal from "./SubmitCustomModal";
import { showErrors } from "store/exception/actions";
import { loaderLock, loaderUnlock } from "store/common/actions";
import { AOMapper } from "infrastructure/mappers";
import { IAO } from "infrastructure/interfaces";
import { getAdvanceReportApplication } from "../../../store/selectors";
import { getAdvanceReportApplications } from "../../../services/ApiService";
import { ADVANCE_APPLICATION_PROPETIES } from "infrastructure/enum/object-properties.enum";

interface ADActFillerProps {
  id: number;
  isAdvance?: boolean;
  buttonOption: {
    className: string;
    text: string;
    onClick: () => void;
  };
}

const ADActFiller: React.FC<ADActFillerProps> = (props) => {
  let { t } = useTranslation();
  const dispatch = useDispatch();

  let [lastId, setLastId] = useState(0);
  let [steps, setSteps] = useState(
    [] as {
      type: string;
      id: number;
      documentId: number;
      isFilled: boolean;
      isBusinessReport: boolean;
    }[]
  );
  let [currentStep, setCurrentStep] = useState(-1);

  const report = useSelector(getAdvanceReportApplication);

  useEffect(() => {
    generateStep();
  }, [report]);

  const generateStep = async () => {
    if (props.isAdvance) {
      let additionalDocuments = report.additionalDocuments;
      let allExpensesId = additionalDocuments
        .map((item) => item.expenses)
        .flat()
        .map((item) => item.id);
      let newSteps = report.expenses
        .filter(
          (item) =>
            (item.expenseType.needIncludeToAdvanceReportAdditionalDocuments ||
              item.expenseType.needIncludeToBusinessEventReportDocuments) &&
            !allExpensesId.includes(item.id)
        )
        .map((item) => ({
          isBusinessReport:
            item.expenseType.needIncludeToBusinessEventReportDocuments,
          type: "Expense",
          id: item.id,
          documentId: report.id,
          isFilled: false,
        }));
      let newStepsIds = newSteps.map((item) => item.id);
      if (lastId == props.id) {
        let resultSteps = [
          ...steps.map((item) => ({
            ...item,
            isFilled: item.isFilled || !newStepsIds.includes(item.id),
          })),
          ...newSteps,
        ];
        setSteps(resultSteps);
      } else {
        setCurrentStep(-2);
        setLastId(props.id);
        setSteps(newSteps);
      }
    } else {
      setSteps([]);
    }
  };

  const startActivation = () => {
    let isActiveArray = steps.map((item) => item.isFilled);
    let startIndex = isActiveArray.indexOf(false);
    if (startIndex == -1 && hasActWithoutAttachedExpense()) {
      setCurrentStep(-2);
    } else {
      setCurrentStep(startIndex);
    }
  };

  const onCloseHandler = async (
    isSimpleClose?: boolean,
    isCloseWithNext?: boolean
  ) => {
    if (isSimpleClose) {
      setCurrentStep(-2);
    } else if (isCloseWithNext) {
      steps[currentStep].isFilled = true;
      steps.forEach((item, index) => {
        if (index == currentStep) {
          item.isFilled = true;
        }
        if (item.id == steps[currentStep].id) {
          item.isFilled = true;
        }
      });

      let isActiveArray = steps.map((item) => item.isFilled);
      let nextIndex = isActiveArray.indexOf(false, currentStep);
      if (nextIndex == -1) {
        setCurrentStep(-2);
        let newReport = await updateDetailedAO(report.id);
        hasActWithoutAttachedExpense(newReport ? newReport : undefined)
          ? dispatch(
              showErrors({
                code: "approvals_action",
                message: t("modals.ad_act.message_act"),
              })
            )
          : props.buttonOption.onClick();
      } else {
        setCurrentStep(nextIndex);
      }
    }
  };

  const updateDetailedAO = async (id: number) => {
    dispatch(loaderLock());
    let aoMapper = new AOMapper();
    let response = await getAdvanceReportApplications(id);
    if (response.headers.success) {
      let report = aoMapper.responseToEntity(response.data);
      dispatch(loaderUnlock());
      return report;
    }
    dispatch(loaderUnlock());
    return null;
  };

  const generateNodeArray = () => {
    let generatedNodeArray = [];

    for (let stepIndex = 0; stepIndex < steps.length; stepIndex++) {
      const stepsElement = steps[stepIndex];
      let hasNext =
        steps.map((item) => item.isFilled).indexOf(false, stepIndex + 1) >= 0;

      if (stepsElement.type == "Expense") {
        generatedNodeArray.push(
          <div className="tx-left">
            <CreateOrderModal
              id={stepsElement.documentId}
              editId={stepsElement.id}
              isAdvance={true}
              isOpen={stepIndex == currentStep}
              onClose={onCloseHandler}
              hasNext={hasNext}
            />
          </div>
        );
      }
    }
    return generatedNodeArray;
  };

  const onStartHandler = () => {
    if (steps.filter((item) => !item.isFilled).length > 0) {
      setCurrentStep(-1);
      return;
    }
    const documents = hasActWithoutAttachedExpense();

    if (!!documents.length) {
      if (
        documents.some(
          (e) =>
            e.documentType ===
            ADVANCE_APPLICATION_PROPETIES.ACT_OF_ENTERTAINMENT_EXPENSES
        )
      ) {
        dispatch(
          showErrors({
            code: "approvals_action",
            message: t("modals.ad_act.message_act"),
          })
        );
      } else {
        dispatch(
          showErrors({
            code: "approvals_action",
            message: t("modals.ad_act.message_report"),
          })
        );
      }
      return;
    }
  };

  const onStopHandler = () => {
    setCurrentStep(-2);
  };

  const hasActWithoutAttachedExpense = (newReport?: IAO) => {
    if (props.isAdvance) {
      let additionalDocuments = newReport
        ? newReport.additionalDocuments
        : report.additionalDocuments;
      let hasActWithoutAttachedExpense = additionalDocuments.filter(
        (item) =>
          item.expenses.length == 0 && item.documentType !== "ServiceNote"
      );
      return hasActWithoutAttachedExpense;
    }
    return [];
  };

  return (
    <>
      {steps.filter((item) => !item.isFilled).length > 0 ||
      !!hasActWithoutAttachedExpense().length ? (
        <>
          <div
            className={props.buttonOption.className}
            onClick={onStartHandler}
          >
            {props.buttonOption.text}
          </div>
        </>
      ) : (
        <>
          <div
            className={props.buttonOption.className}
            onClick={props.buttonOption.onClick}
          >
            {props.buttonOption.text}
          </div>
        </>
      )}
      <SubmitCustomModal
        isOpen={currentStep == -1}
        title={
          steps.some((item) => !item.isFilled && item.isBusinessReport)
            ? t("modals.ad_act.message_report_exp")
            : t("modals.ad_act.message_act_exp")
        }
        closeButtonText={t("modals.ad_act.cancel")}
        onClose={onStopHandler}
        onSubmit={startActivation}
      />
      {generateNodeArray()}
    </>
  );
};

export default ADActFiller;
